// This script is loaded in the wordpress websites to store url parameters to session storage and to bind attributes to MFE.
(function () {
	const urlParams = new URLSearchParams(window.location.search);

	const collectCampaignParams = () => {
		const filteredParams = {};

		for (const [key, value] of urlParams) {
			if (key.startsWith('mtm_') || key.startsWith('utm_')) {
				filteredParams[key] = value;
			}
		}

		return filteredParams;
	};

	const getArrayParam = (paramName) => {
		const paramValue = urlParams.get(paramName);

		return paramValue ? paramValue.split(',').map(id => +id) : [];
	};

	const getStoredState = () => {
		return JSON.parse(sessionStorage.getItem('dataWheelState') || '{}');
	};

	const getDataWheelState = () => {
		return window.btoa(JSON.stringify(getStoredState()));
	};

	const view = urlParams.get('view');
	const colorId = +urlParams.get('colorId');
	const radiusZoomFactor = +urlParams.get('radiusZoomFactor');
	const campaignLessonId = +urlParams.get('campaignLessonId');

	const subjectIds = getArrayParam('subjectIds');
	const gradeIds = getArrayParam('gradeIds');
	const courseGroupIds = getArrayParam('courseGroupIds');
	const educationalStandardIds = getArrayParam('educationalStandardIds');
	const cycleIds = getArrayParam('cycleIds');
	const subMarketIds = getArrayParam('subMarketIds');

	const storedState = getStoredState();

	// Numbers are referring to data-filter.model.ts
	const filter = {
		7: subjectIds,
		4: gradeIds,
		8: courseGroupIds,
		1: educationalStandardIds,
		12: cycleIds,
		13: subMarketIds
	}

	const state = {
		...storedState,
		filter: storedState.filter || (Object.values(filter).some(f => f.length > 0) ? filter : null),
		view: storedState.view || view,
		colorId: storedState.colorId || colorId,
		radiusZoomFactor: storedState.radiusZoomFactor || radiusZoomFactor
	};

	// Apply state to session storage

	sessionStorage.setItem('dataWheelState', JSON.stringify(state));
	sessionStorage.setItem('campaignLessonId', campaignLessonId);

	// Bind state to Data Wheel MFE

	const dataWheelEl = document.querySelector('snappet-data-wheel');

	if (dataWheelEl) {
		const campaign = urlParams.get('campaign') || window.snappet.defaultCampaign;

		const campaignParams = collectCampaignParams();
		const campaignQueryString = Object.entries(campaignParams)
			.map(([key, value]) => `${key}=${value}`)
			.join('&');

		const lessonPrepareUrl = window.snappet.lessonPrepareUrl;
		const registerUrl = window.snappet.registerUrl;
		const dashboardUrl = window.snappet.dashboardUrl;

		dataWheelEl.addEventListener('lessonClick', (event) => {
			const lessonPath = event.detail;

			const lessonPathQueryString = Object.entries(lessonPath).filter(([, value]) => value !== undefined).map(([key, value]) => `${key}=${value}`).join('&');
			const redirectQueryString = [lessonPathQueryString, `dataWheelState=${getDataWheelState()}`, `campaign=${campaign}`, campaignQueryString].filter(qs => !!qs).join('&');
			const url = `${lessonPrepareUrl}?${redirectQueryString}`;

			location.assign(url);
		});

		dataWheelEl.addEventListener('loginClick', () => {
			const redirectQueryString = [`dataWheelState=${getDataWheelState()}`, `campaign=${campaign}`].filter(qs => !!qs).join('&');
			const redirectUri = window.encodeURIComponent(`${dashboardUrl}?${redirectQueryString}`);
			const queryString = [`redirect_uri=${redirectUri}`, campaignQueryString].filter(qs => !!qs).join('&');
			const url = `${registerUrl}?${queryString}`;

			location.assign(url);
		});

		dataWheelEl.addEventListener('renderingDone', () => {
			var loaderContainer = document.querySelector('.loader-container');
			loaderContainer.style.opacity = '0';
			setTimeout(function () {
				loaderContainer.style.display = 'none';
			}, 500);
		});
	}
})();
